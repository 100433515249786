import { useMemo } from 'react';

import { ROOTS_TYPE, PATHS_ADMIN, PATHS_DASHBOARD } from 'src/routes/paths';

import { identifyRoles } from 'src/utils/generate-paths';

import { useTranslate } from 'src/locales';
import { UserRoleType } from 'src/auth/types';
// import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

// const icon = (name: string) => (
//   <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
//   // OR
//   // <Iconify icon="fluent:mail-24-filled" />
//   // https://icon-sets.iconify.design/solar/
//   // https://www.streamlinehq.com/icons
// );

// const ICONS = {
//   job: icon('ic_job'),
//   blog: icon('ic_blog'),
//   chat: icon('ic_chat'),
//   mail: icon('ic_mail'),
//   user: icon('ic_user'),
//   file: icon('ic_file'),
//   lock: icon('ic_lock'),
//   tour: icon('ic_tour'),
//   order: icon('ic_order'),
//   label: icon('ic_label'),
//   blank: icon('ic_blank'),
//   kanban: icon('ic_kanban'),
//   folder: icon('ic_folder'),
//   banking: icon('ic_banking'),
//   booking: icon('ic_booking'),
//   invoice: icon('ic_invoice'),
//   product: icon('ic_product'),
//   calendar: icon('ic_calendar'),
//   disabled: icon('ic_disabled'),
//   external: icon('ic_external'),
//   menuItem: icon('ic_menu_item'),
//   ecommerce: icon('ic_ecommerce'),
//   analytics: icon('ic_analytics'),
//   dashboard: icon('ic_dashboard'),
//   cluster_admin: {
//     statistics: icon('dashboard'),
//   },
//   operator: {
//     statistics: icon('dashboard'),
//   },
// };

// ----------------------------------------------------------------------

export function useNavData(role: ROOTS_TYPE) {
  const { t } = useTranslate();

  const data = useMemo(
    () => [
      {
        subheader: '',
        items: [
          {
            title: t('menu.dashboard'),
            path: PATHS_DASHBOARD(role).statistics,
            roles: [
              UserRoleType.CLUSTER_ADMIN,
              UserRoleType.OPERATOR,
              UserRoleType.EVALUATOR,
              UserRoleType.VENDOR,
            ],
          },
          {
            title: t('menu.project.title'),
            path: PATHS_DASHBOARD(role).projects.root,
            roles: [UserRoleType.CLUSTER_ADMIN, UserRoleType.OPERATOR, UserRoleType.VENDOR],
            children: [
              {
                title: t('menu.project.all_project'),
                path: PATHS_DASHBOARD(role).projects.all_project,
                roles: [UserRoleType.CLUSTER_ADMIN, UserRoleType.VENDOR],
              },
              {
                title: t('menu.project.project_requests'),
                path: PATHS_DASHBOARD(role).projects.project_requests,
                roles: [UserRoleType.CLUSTER_ADMIN],
              },
              {
                title: t('menu.project.my_projects'),
                path: PATHS_DASHBOARD(role).projects.all_project,
                roles: [UserRoleType.OPERATOR],
              },
              {
                title: t('menu.project.add_project'),
                path: PATHS_DASHBOARD(role).projects.add_project,
                roles: [UserRoleType.CLUSTER_ADMIN, UserRoleType.OPERATOR],
              },
            ],
          },
          {
            title: t('menu.campaign.title'),
            path: PATHS_DASHBOARD(role).campaigns.root,
            roles: [UserRoleType.CLUSTER_ADMIN, UserRoleType.OPERATOR, UserRoleType.VENDOR],
            children: [
              {
                title: t(
                  `menu.campaign.${
                    role === UserRoleType.ADMIN.toUpperCase() ? 'all_campaign' : 'my_campaigns'
                  }`
                ),
                path: PATHS_DASHBOARD(role).campaigns.all_campaign,
                roles: [UserRoleType.CLUSTER_ADMIN, UserRoleType.OPERATOR],
              },
              {
                title: t('menu.campaign.add_campaign'),
                path: PATHS_DASHBOARD(role).campaigns.add_campaign,
                roles: [UserRoleType.CLUSTER_ADMIN, UserRoleType.OPERATOR],
              },
              {
                title: t('menu.campaign.vendor_requests'),
                path: PATHS_DASHBOARD(role).campaigns.vendor_requests,
                roles: [UserRoleType.OPERATOR, UserRoleType.CLUSTER_ADMIN],
              },
              {
                title: t('menu.campaign.all_campaign'),
                path: PATHS_DASHBOARD(role).campaigns.all_campaign,
                roles: [UserRoleType.VENDOR],
              },
              {
                title: t('menu.campaign.my_campaigns'),
                path: PATHS_DASHBOARD(role).campaigns.my_campaigns,
                roles: [UserRoleType.VENDOR],
              },
              // {
              //   title: t('menu.campaign.vendor_favorites_campaign'),
              //   path: PATHS_DASHBOARD(role).campaigns.favorites_campaign,
              //   roles: [UserRoleType.VENDOR],
              // },

              // {
              //   title: t('menu.campaign.send_back_requests'),
              //   path: PATHS_DASHBOARD(role).campaigns.send_back_requests,
              //   roles: [UserRoleType.CLUSTER_ADMIN, UserRoleType.OPERATOR, UserRoleType.VENDOR],
              // },
            ],
          },
          {
            title: t('menu.marketplace.title'),
            path: identifyRoles(role)?.marketplace.root ?? PATHS_ADMIN.marketplace.root,
            roles: [UserRoleType.CLUSTER_ADMIN, UserRoleType.OPERATOR],
            children: [
              {
                title: t('menu.marketplace.all_item'),
                path: identifyRoles(role)?.marketplace.all_item ?? PATHS_ADMIN.marketplace.all_item,
                roles: [UserRoleType.CLUSTER_ADMIN, UserRoleType.OPERATOR],
              },
              {
                title: t('section.marketplace.button.add_new_item'),
                path: identifyRoles(role)?.marketplace.add_item ?? PATHS_ADMIN.marketplace.add_item,
                roles: [UserRoleType.CLUSTER_ADMIN, UserRoleType.OPERATOR],
              },
            ],
          },
          {
            title: t('menu.donors.title'),
            path: PATHS_DASHBOARD(role).donors.root,
            roles: [UserRoleType.CLUSTER_ADMIN],
          },
          {
            title: t('menu.vendors.title'),
            path: PATHS_DASHBOARD(role).vendors.root,
            roles: [UserRoleType.CLUSTER_ADMIN],
            children: [
              {
                title: t('menu.vendors.all_vendor'),
                path: PATHS_DASHBOARD(role).vendors.all_vendor,
                roles: [UserRoleType.CLUSTER_ADMIN],
              },
              {
                title: t('menu.vendors.vendor_requests'),
                path: PATHS_DASHBOARD(role).vendors.requests_vendor,
                roles: [UserRoleType.CLUSTER_ADMIN],
              },
              {
                title: t('menu.vendors.add_vendor'),
                path: PATHS_DASHBOARD(role).vendors.add_vendor,
                roles: [UserRoleType.CLUSTER_ADMIN],
              },
            ],
          },
          {
            title: t('menu.operators.title'),
            path: identifyRoles(role)?.operators.all_operator ?? PATHS_ADMIN.operators.all_operator,
            roles: [UserRoleType.CLUSTER_ADMIN],
          },
          {
            title: t('menu.evaluators.title'),
            path: PATHS_DASHBOARD(role).evaluators.root,
            roles: [UserRoleType.CLUSTER_ADMIN],
            children: [
              {
                title: t('menu.evaluators.all_evaluator'),
                path:
                  PATHS_DASHBOARD(role)?.evaluators.all_evaluator ??
                  PATHS_ADMIN.evaluators.all_evaluator,
                roles: [UserRoleType.CLUSTER_ADMIN],
              },
              {
                title: t('menu.evaluators.maintenance_requests'),
                path: PATHS_DASHBOARD(role)?.evaluators.evaluator_maintenance_requests,
                roles: [UserRoleType.CLUSTER_ADMIN],
              },
              {
                title: t('section.maintenance_request.caption.all_mosques_evaluated'),
                path: PATHS_DASHBOARD(role).evaluators.all_mosques_evaluated,
                roles: [UserRoleType.CLUSTER_ADMIN],
              },
            ],
          },
          {
            title: t('section.volunteers.title'),
            path: PATHS_DASHBOARD(role).volunteers.root,
            roles: [UserRoleType.CLUSTER_ADMIN, UserRoleType.OPERATOR],
            children: [
              {
                title: t('section.volunteers.caption.all_volunteer'),
                path: PATHS_DASHBOARD(role).volunteers.all_volunteer,
                roles: [UserRoleType.CLUSTER_ADMIN],
              },
              {
                title: t('section.volunteers.caption.all_campaign_task'),
                path: PATHS_DASHBOARD(role).volunteers.all_campaign_task,
                roles: [UserRoleType.CLUSTER_ADMIN, UserRoleType.OPERATOR],
              },
              {
                title: t('section.volunteers.button.add_new_task'),
                path: PATHS_DASHBOARD(role).volunteers.add_new_task,
                roles: [UserRoleType.CLUSTER_ADMIN, UserRoleType.OPERATOR],
              },
            ],
          },
          {
            title: t('menu.tickets.title'),
            path: PATHS_DASHBOARD(role).tickets.root,
            roles: [UserRoleType.CLUSTER_ADMIN, UserRoleType.OPERATOR, UserRoleType.VENDOR],
            children: [
              {
                title: t('menu.tickets.all_ticket'),
                path: PATHS_DASHBOARD(role).tickets.all_tickets,
                roles: [UserRoleType.CLUSTER_ADMIN, UserRoleType.OPERATOR, UserRoleType.VENDOR],
              },
              {
                title: t('section.ticket.caption.add_new_ticket'),
                path: PATHS_DASHBOARD(role).tickets.add_ticket,
                roles: [UserRoleType.CLUSTER_ADMIN],
              },
            ],
          },
          // {
          //   title: t('menu.directories'),
          //   path: PATHS_ADMIN.directories,
          //   roles: [UserRoleType.CLUSTER_ADMIN],
          // },
          {
            title:
              role.toLowerCase() === UserRoleType.ADMIN
                ? t('menu.maintenance_request')
                : t('section.maintenance_request.caption.evaluation'),
            path: PATHS_DASHBOARD(role).maintenance_request.root,
            roles: [UserRoleType.CLUSTER_ADMIN, UserRoleType.EVALUATOR],
            children: [
              {
                title: t('section.maintenance_request.caption.my_evaluation_task'),
                path: PATHS_DASHBOARD(role).maintenance_request.my_evaluation_task,
                roles: [UserRoleType.EVALUATOR],
              },
              // {
              //   title: t('section.maintenance_request.caption.project_evaluated'),
              //   path: PATHS_DASHBOARD(role).maintenance_request.project_evaluated,
              //   roles: [UserRoleType.EVALUATOR],
              // },
              {
                title:
                  role.toLowerCase() === UserRoleType.ADMIN
                    ? t('section.maintenance_request.caption.all_request')
                    : t('section.maintenance_request.caption.all_evaluation_request'),
                path: PATHS_DASHBOARD(role).maintenance_request.all_maintenance_request,
                roles: [UserRoleType.CLUSTER_ADMIN, UserRoleType.EVALUATOR],
              },
            ],
          },
          // {
          //   title: t('menu.community'),
          //   path: PATHS_ADMIN.community,
          //   roles: [UserRoleType.CLUSTER_ADMIN],
          // },
          // {
          //   title: t('menu.blogs'),
          //   path: PATHS_ADMIN.blogs,
          //   roles: [UserRoleType.CLUSTER_ADMIN],
          // },
        ],
      },
    ],
    [role, t]
  );

  return data;
}
