import { lazy, Suspense } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import { identifyRoles } from 'src/utils/generate-paths';

import DashboardLayout from 'src/layouts/dashboard';
import { AuthGuard, RoleBasedGuard } from 'src/auth/guard';

import { LoadingScreen } from 'src/components/loading-screen';

import { PATHS_DASHBOARD } from '../paths';

// ----------------------------------------------------------------------

// OVERVIEW
const StatisticsPage = lazy(() => import('src/pages/admin/statistics'));

// PROJECTS
const ProjectsPage = lazy(() => import('src/pages/project/all'));
const AddProjectPage = lazy(() => import('src/pages/project/add'));
const DetailsProjectPage = lazy(() => import('src/pages/project/details'));
const EditProjectPage = lazy(() => import('src/pages/project/edit'));
const EditRequestProjectPage = lazy(() => import('src/pages/project/edit-request'));
const CampaignListOnProjectPage = lazy(() => import('src/pages/project/campaign-list'));
const ItemListOnProjectPage = lazy(() => import('src/pages/project/item-list'));
const ProjectRequestsPage = lazy(() => import('src/pages/project/requests'));

// CAMPAIGN
const AllCampaignPage = lazy(() => import('src/pages/campaign/all'));
const AddCampaignPage = lazy(() => import('src/pages/campaign/add'));
const DetailsCampaignPage = lazy(() => import('src/pages/campaign/details'));
const EditCampaignPage = lazy(() => import('src/pages/campaign/edit'));
const EditRequestCampaignPage = lazy(() => import('src/pages/campaign/edit-request'));
const CampaignsTransactionsPage = lazy(() => import('src/pages/campaign/transaction-list'));
const CampaignsTransactionDonorsPage = lazy(
  () => import('src/pages/campaign/transaction-donors-list')
);
const ProjectTransactionDonorsPage = lazy(
  () => import('src/pages/project/transaction-donors-list')
);
const CampaignVendorRequestsPage = lazy(() => import('src/pages/campaign/vendor-requests'));
const CampaignDetailVendorRequestPage = lazy(
  () => import('src/pages/campaign/detail-vendor-request')
);
const CampaignVendorsPage = lazy(() => import('src/pages/campaign/vendor-list'));
const CampaignVendorDetailsPage = lazy(() => import('src/pages/campaign/vendor-details'));
const SendBackRequestCampaignPage = lazy(() => import('src/pages/campaign/send-back-requests'));

// MARKETPLACE item
const AllMarketplaceItemPage = lazy(() => import('src/pages/marketplace-item/all'));
const AddMarketplaceItemPage = lazy(() => import('src/pages/marketplace-item/add'));
const DetailsMarketplaceItemPage = lazy(() => import('src/pages/marketplace-item/details'));
const EditMarketplaceItemPage = lazy(() => import('src/pages/marketplace-item/edit'));
const EditRequestMarketplaceItemPage = lazy(
  () => import('src/pages/marketplace-item/edit-request')
);
const MarketplaceItemsTransactionDonorsPage = lazy(
  () => import('src/pages/marketplace-item/transaction-donors-list')
);

// Operators
const AllOperatorPage = lazy(() => import('src/pages/operator/all'));
const OperatorDetailsPage = lazy(() => import('src/pages/operator/details'));
const AddOperatorPage = lazy(() => import('src/pages/operator/add'));
const UpdateOperatorPage = lazy(() => import('src/pages/operator/update-operator'));

// Evaluators
const AllEvaluatorPage = lazy(() => import('src/pages/evaluator/all'));
const AddEvaluatorPage = lazy(() => import('src/pages/evaluator/add'));
const EvaluatorDetailsPage = lazy(() => import('src/pages/evaluator/details'));
const UpdateEvaluator = lazy(() => import('src/pages/evaluator/update-evaluator'));
const EvaluatorsMaintenanceRequests = lazy(
  () => import('src/pages/evaluator/maintenance-requests')
);
const EvaluatorsMaintenanceRequestDetails = lazy(
  () => import('src/pages/evaluator/maintenance-request-details')
);
const AddMosqueToMaintenancePage = lazy(
  () => import('src/pages/evaluator/add-mosque-to-maintenance')
);
const EvaluatorChatPage = lazy(() => import('src/pages/evaluator/chat'));

const MainreqProjectEvaluatedListPage = lazy(
  () => import('src/pages/maintenance-requests/project-evaluated')
);
const MainreqProjectEvaluatedDetailsPage = lazy(
  () => import('src/pages/maintenance-requests/detail-project-evaluated')
);

// Vendors
const AllVendorPage = lazy(() => import('src/pages/vendor/all'));
const AddVendorPage = lazy(() => import('src/pages/vendor/add'));
const VendorEditPage = lazy(() => import('src/pages/vendor/edit'));
const VendorRequestsPage = lazy(() => import('src/pages/vendor/requests'));
const VendorDetailPage = lazy(() => import('src/pages/vendor/detail'));
const VendorDetailRequestsPage = lazy(() => import('src/pages/vendor/detail-requests'));
const VendorMilestonProgressPage = lazy(() => import('src/pages/vendor/milestone-progress'));
const VendorMilestoneInvoicesPage = lazy(() => import('src/pages/vendor/milestone-invoices'));
const COPayToVendorPage = lazy(() => import('src/pages/campaign/operator-pay-to-vendor'));

// Donors
const AllDonorPage = lazy(() => import('src/pages/donor/all'));
const DonorDetailPage = lazy(() => import('src/pages/donor/detail'));

// Tickets
const AllTicketsPage = lazy(() => import('src/pages/ticket/all'));
const TicketDetailPage = lazy(() => import('src/pages/ticket/detail'));
const AddNewTicketPage = lazy(() => import('src/pages/ticket/add'));

// Settings
const SettingsAccountInfoPage = lazy(() => import('src/pages/settings/account-info'));

// Maintenance Requests
const MaintenanceRequestListPage = lazy(() => import('src/pages/maintenance-requests/all'));
const DetailsMaintenanceRequestPage = lazy(() => import('src/pages/maintenance-requests/details'));

// Volunteer
const AllVolunteerPage = lazy(() => import('src/pages/volunteer/all'));
const DetailVolunteerRequestPage = lazy(() => import('src/pages/volunteer/detail-request'));
const VolunteerAddNewTaskPage = lazy(() => import('src/pages/volunteer/add-new-task'));
const VolunteerCampaignTaskListPage = lazy(() => import('src/pages/volunteer/campaign-tasks'));
const VolunteerCampaignTaskDetailPage = lazy(
  () => import('src/pages/volunteer/detail-campaign-task')
);
const VolunteerCampaignTaskEditPage = lazy(() => import('src/pages/volunteer/edit-campaign-task'));
const VolunteerApplyTaskListPage = lazy(() => import('src/pages/volunteer/apply-tasks'));

// ----------------------------------------------------------------------

const identifiedPaths = identifyRoles('cluster_admin');

const identifiedPathsV2 = PATHS_DASHBOARD('ADMIN');

// ----------------------------------------------------------------------

export const clusterAdminRoutes = {
  path: 'admin',
  element: (
    <AuthGuard>
      <RoleBasedGuard hasContent roles={['CLUSTER_ADMIN']} sx={{ py: 10 }}>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </RoleBasedGuard>
    </AuthGuard>
  ),
  children: [
    { element: <Navigate to="/admin/dashboard/statistics" replace />, index: true },
    {
      path: 'dashboard',
      children: [
        { element: <Navigate to="/admin/dashboard/statistics" replace />, index: true },
        {
          path: 'statistics',
          element: <StatisticsPage title="menu.dashboard" activeRole="roles.CLUSTER_ADMIN" />,
        },
      ],
    },
    {
      path: 'project',
      children: [
        { element: <Navigate to="/admin/project/all" replace />, index: true },
        {
          path: 'detail',
          children: [
            { element: <Navigate to="/admin/project/all" replace />, index: true },
            {
              path: ':id',
              children: [
                {
                  path: '',
                  element: <DetailsProjectPage paths={identifiedPaths!} />,
                },
                {
                  path: 'campaigns',
                  element: (
                    <CampaignListOnProjectPage
                      title="menu.campaign.title"
                      activeRole="roles.CLUSTER_ADMIN"
                      paths={identifiedPathsV2}
                    />
                  ),
                },
                {
                  path: 'items',
                  element: (
                    <ItemListOnProjectPage
                      title="menu.marketplace.title"
                      activeRole="roles.CLUSTER_ADMIN"
                      paths={identifiedPaths!}
                    />
                  ),
                },
                {
                  path: 'donors',
                  element: (
                    <ProjectTransactionDonorsPage
                      title="menu.campaign.donor_list_project"
                      activeRole="roles.CLUSTER_ADMIN"
                      paths={identifiedPaths!}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          path: 'edit-request',
          children: [
            { element: <Navigate to="/admin/project/all" replace />, index: true },
            {
              path: ':id',
              element: (
                <EditRequestProjectPage
                  title="menu.project.edit_request"
                  activeRole="roles.CLUSTER_ADMIN"
                  paths={identifiedPaths!}
                />
              ),
            },
          ],
        },
        {
          path: 'edit',
          children: [
            { element: <Navigate to="/admin/project/all" replace />, index: true },
            {
              path: ':id',
              element: (
                <EditProjectPage
                  title="menu.project.edit"
                  activeRole="roles.CLUSTER_ADMIN"
                  paths={identifiedPaths!}
                />
              ),
            },
          ],
        },

        {
          path: 'all',
          element: (
            <ProjectsPage
              title="menu.project.all_project"
              activeRole="roles.CLUSTER_ADMIN"
              paths={identifiedPathsV2}
            />
          ),
        },
        {
          path: 'add',
          element: (
            <AddProjectPage
              title="menu.project.add_project"
              activeRole="roles.CLUSTER_ADMIN"
              paths={identifiedPaths!}
            />
          ),
        },
        {
          path: 'requests',
          element: (
            <ProjectRequestsPage
              title="menu.project.project_requests"
              activeRole="roles.CLUSTER_ADMIN"
              paths={identifiedPathsV2}
            />
          ),
        },
      ],
    },
    {
      path: 'campaign',
      children: [
        { element: <Navigate to="/admin/campaign/all" replace />, index: true },
        {
          path: 'detail',
          children: [
            { element: <Navigate to="/admin/campaign/all" replace />, index: true },
            {
              path: ':id',
              children: [
                {
                  path: '',
                  element: (
                    <DetailsCampaignPage
                      title="menu.campaign.details"
                      activeRole="roles.CLUSTER_ADMIN"
                      paths={identifiedPathsV2}
                    />
                  ),
                },
                {
                  path: 'payment-history',
                  element: (
                    <CampaignsTransactionsPage
                      title="menu.campaign.payment_history"
                      activeRole="roles.CLUSTER_ADMIN"
                      paths={identifiedPaths!}
                    />
                  ),
                },
                {
                  path: 'donors',
                  element: (
                    <CampaignsTransactionDonorsPage
                      title="menu.campaign.donor_list_campaign"
                      activeRole="roles.CLUSTER_ADMIN"
                      paths={identifiedPaths!}
                    />
                  ),
                },
                {
                  path: 'vendors',
                  children: [
                    {
                      path: '',
                      element: (
                        <CampaignVendorsPage
                          title="menu.vendors.title"
                          activeRole="roles.CLUSTER_ADMIN"
                          paths={identifiedPathsV2}
                        />
                      ),
                    },
                    {
                      path: ':vendor_id',
                      children: [
                        {
                          path: '',
                          element: (
                            <CampaignVendorDetailsPage
                              title="menu.vendors.detail_vendor"
                              activeRole="roles.CLUSTER_ADMIN"
                              paths={identifiedPathsV2}
                            />
                          ),
                        },
                        {
                          path: 'progresses',
                          element: (
                            <VendorMilestonProgressPage
                              title="menu.vendors.milestone_progress"
                              activeRole="roles.CLUSTER_ADMIN"
                              paths={identifiedPathsV2}
                            />
                          ),
                        },
                        {
                          path: 'invoices',
                          element: (
                            <VendorMilestoneInvoicesPage
                              title="menu.vendors.milestone_invoices"
                              activeRole="roles.CLUSTER_ADMIN"
                              paths={identifiedPathsV2}
                            />
                          ),
                        },
                        {
                          path: 'pay-to-vendor',
                          element: (
                            <COPayToVendorPage
                              title="section.admin.dashboard.label.pay_to_vendor"
                              activeRole="roles.OPERATOR"
                              paths={identifiedPathsV2}
                            />
                          ),
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: 'edit-request',
          children: [
            { element: <Navigate to="/admin/campaign/all" replace />, index: true },
            {
              path: ':id',
              element: (
                <EditRequestCampaignPage
                  title="menu.campaign.edit_request"
                  activeRole="roles.CLUSTER_ADMIN"
                  paths={identifiedPaths!}
                />
              ),
            },
          ],
        },
        {
          path: 'edit',
          children: [
            { element: <Navigate to="/admin/campaign/all" replace />, index: true },
            {
              path: ':id',
              element: (
                <EditCampaignPage
                  title="menu.campaign.edit_campaign"
                  activeRole="roles.CLUSTER_ADMIN"
                  paths={identifiedPaths!}
                />
              ),
            },
          ],
        },
        {
          path: 'all',
          element: (
            <AllCampaignPage
              title="menu.campaign.all_campaign"
              activeRole="roles.CLUSTER_ADMIN"
              paths={identifiedPathsV2}
            />
          ),
        },
        {
          path: 'add',
          element: (
            <AddCampaignPage
              title="menu.campaign.add_campaign"
              activeRole="roles.CLUSTER_ADMIN"
              paths={identifiedPaths!}
            />
          ),
        },
        {
          path: 'vendor-requests',
          children: [
            {
              path: '',
              element: (
                <CampaignVendorRequestsPage
                  title="menu.campaign.vendor_requests"
                  activeRole="roles.CLUSTER_ADMIN"
                  paths={identifiedPathsV2}
                />
              ),
            },
            {
              path: ':id',
              element: (
                <CampaignDetailVendorRequestPage
                  title="menu.campaign.detail_vendor_request"
                  activeRole="roles.CLUSTER_ADMIN"
                  paths={identifiedPathsV2}
                />
              ),
            },
          ],
        },
        {
          path: 'send-back-requests',
          element: (
            <SendBackRequestCampaignPage
              title="menu.campaign.send_back_requests"
              activeRole="roles.CLUSTER_ADMIN"
              paths={identifiedPathsV2}
            />
          ),
        },
      ],
    },
    {
      path: 'marketplace',
      children: [
        { element: <Navigate to="/admin/marketplace/all" replace />, index: true },
        {
          path: 'all',
          element: (
            <AllMarketplaceItemPage
              title="section.marketplace.title.project_marketplace"
              activeRole="roles.CLUSTER_ADMIN"
              paths={identifiedPaths!}
            />
          ),
        },
        {
          path: 'add',
          element: (
            <AddMarketplaceItemPage
              title="section.marketplace.button.add_new_item"
              activeRole="roles.CLUSTER_ADMIN"
              paths={identifiedPaths!}
            />
          ),
        },
        {
          path: 'detail',
          children: [
            { element: <Navigate to="/admin/marketplace/all" replace />, index: true },
            {
              path: ':id',
              children: [
                {
                  path: '',
                  element: (
                    <DetailsMarketplaceItemPage
                      title="menu.marketplace.details"
                      activeRole="roles.CLUSTER_ADMIN"
                      paths={identifiedPaths!}
                    />
                  ),
                },
                {
                  path: 'donors',
                  element: (
                    <MarketplaceItemsTransactionDonorsPage
                      title="menu.marketplace.donor_list_item"
                      activeRole="roles.CLUSTER_ADMIN"
                      paths={identifiedPaths!}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          path: 'edit',
          children: [
            { element: <Navigate to="/admin/marketplace/all" replace />, index: true },
            {
              path: ':id',
              element: (
                <EditMarketplaceItemPage
                  title="menu.marketplace.edit_item"
                  activeRole="roles.CLUSTER_ADMIN"
                  paths={identifiedPaths!}
                />
              ),
            },
          ],
        },
        {
          path: 'edit-request',
          children: [
            { element: <Navigate to="/admin/marketplace/all" replace />, index: true },
            {
              path: ':id',
              element: (
                <EditRequestMarketplaceItemPage
                  title="menu.marketplace.edit_request_item"
                  activeRole="roles.CLUSTER_ADMIN"
                  paths={identifiedPaths!}
                />
              ),
            },
          ],
        },
      ],
    },
    {
      path: 'evaluator',
      children: [
        { element: <Navigate to="/admin/evaluator/all" replace />, index: true },
        {
          path: 'all',
          element: (
            <AllEvaluatorPage
              title="menu.evaluators.title"
              activeRole="roles.CLUSTER_ADMIN"
              paths={identifiedPathsV2}
            />
          ),
        },
        {
          path: '/admin/evaluator/detail/:evaluator_id',
          element: (
            <EvaluatorDetailsPage
              title="menu.evaluators.details"
              activeRole="roles.CLUSTER_ADMIN"
              paths={identifiedPathsV2}
            />
          ),
        },
        {
          path: '/admin/evaluator/add',
          element: (
            <AddEvaluatorPage
              title="menu.evaluators.add_evaluator"
              activeRole="roles.CLUSTER_ADMIN"
              paths={identifiedPathsV2}
            />
          ),
        },
        {
          path: '/admin/evaluator/update/:id',
          element: (
            <UpdateEvaluator
              title="menu.evaluators.update_evaluator"
              activeRole="roles.CLUSTER_ADMIN"
              paths={identifiedPathsV2}
            />
          ),
        },
        {
          path: '/admin/evaluator/maintenance-requests',
          element: (
            <EvaluatorsMaintenanceRequests
              title="menu.evaluators.maintenance_requests"
              activeRole="roles.CLUSTER_ADMIN"
              paths={identifiedPathsV2}
            />
          ),
        },
        {
          path: '/admin/evaluator/maintenance-requests/:request_id',
          element: (
            <EvaluatorsMaintenanceRequestDetails
              title="menu.evaluators.evaluators_request"
              activeRole="roles.CLUSTER_ADMIN"
              paths={identifiedPathsV2}
            />
          ),
        },
        {
          path: '/admin/evaluator/chat/:request_id',
          element: (
            <EvaluatorChatPage
              title="menu.evaluators.chat_with_super_admin"
              activeRole="roles.CLUSTER_ADMIN"
              paths={identifiedPathsV2}
            />
          ),
        },
        {
          path: '/admin/evaluator/add-mosque-to-maintenance',
          element: (
            <AddMosqueToMaintenancePage
              title="menu.mosques.add_mosque"
              activeRole="roles.CLUSTER_ADMIN"
              paths={identifiedPathsV2}
            />
          ),
        },
        {
          path: 'mosques-evaluated',
          children: [
            {
              path: '',
              element: (
                <MainreqProjectEvaluatedListPage
                  title="section.maintenance_request.caption.all_mosques_evaluated"
                  activeRole="roles.CLUSTER_ADMIN"
                  paths={identifiedPathsV2}
                />
              ),
            },
            {
              path: 'detail',
              children: [
                {
                  element: (
                    <Navigate
                      to="/admin/evaluator/maintenance-requests/mosques-evaluated"
                      replace
                    />
                  ),
                  index: true,
                },
                {
                  path: ':evaluation_task_id',
                  element: (
                    <MainreqProjectEvaluatedDetailsPage
                      title="section.maintenance_request.caption.detail_mosques_evaluated"
                      activeRole="roles.CLUSTER_ADMIN"
                      paths={identifiedPathsV2}
                    />
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: 'operator',
      children: [
        { element: <Navigate to="/admin/operator/all" replace />, index: true },
        {
          path: 'all',
          element: (
            <AllOperatorPage
              title="menu.operators.title"
              activeRole="roles.CLUSTER_ADMIN"
              paths={identifiedPathsV2}
            />
          ),
        },
        {
          path: '/admin/operator/add',
          element: (
            <AddOperatorPage
              title="menu.operators.add_operator"
              activeRole="roles.CLUSTER_ADMIN"
              paths={identifiedPathsV2}
            />
          ),
        },
        {
          path: '/admin/operator/update/:operator_id',
          element: (
            <UpdateOperatorPage
              title="menu.operators.update_operator"
              activeRole="roles.CLUSTER_ADMIN"
              paths={identifiedPathsV2}
            />
          ),
        },
        {
          path: '/admin/operator/detail/:operator_id',
          element: (
            <OperatorDetailsPage
              title="menu.operators.detail_operator"
              activeRole="roles.CLUSTER_ADMIN"
              paths={identifiedPathsV2}
            />
          ),
        },
      ],
    },
    {
      path: 'vendor',
      children: [
        { element: <Navigate to="/admin/vendor/all" replace />, index: true },
        {
          path: 'all',
          element: (
            <AllVendorPage
              title="menu.vendors.all_vendor"
              activeRole="roles.CLUSTER_ADMIN"
              paths={identifiedPathsV2}
            />
          ),
        },
        {
          path: 'add',
          element: (
            <AddVendorPage
              title="menu.vendors.add_vendor"
              activeRole="roles.CLUSTER_ADMIN"
              paths={identifiedPathsV2}
            />
          ),
        },
        {
          path: 'requests',
          children: [
            {
              path: '',
              element: (
                <VendorRequestsPage
                  title="menu.vendors.vendor_requests"
                  activeRole="roles.CLUSTER_ADMIN"
                  paths={identifiedPathsV2}
                />
              ),
            },
            {
              path: 'detail',
              children: [
                { element: <Navigate to="/admin/vendor/requests" replace />, index: true },
                {
                  path: ':id',
                  element: (
                    <VendorDetailRequestsPage
                      title="menu.vendors.detail_requests_vendor"
                      activeRole="roles.CLUSTER_ADMIN"
                      paths={identifiedPathsV2}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          path: 'detail',
          children: [
            { element: <Navigate to="/admin/vendor/all" replace />, index: true },
            {
              path: ':id',
              children: [
                {
                  path: '',
                  element: (
                    <VendorDetailPage
                      title="menu.vendors.detail_vendor"
                      activeRole="roles.CLUSTER_ADMIN"
                      paths={identifiedPathsV2}
                    />
                  ),
                },
                {
                  path: 'edit',
                  element: (
                    <VendorEditPage
                      title="menu.vendors.edit"
                      activeRole="roles.CLUSTER_ADMIN"
                      paths={identifiedPathsV2}
                    />
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: 'donor',
      children: [
        { element: <Navigate to="/admin/donor/all" replace />, index: true },
        {
          path: 'all',
          element: (
            <AllDonorPage
              title="menu.donors.all_donor"
              activeRole="roles.CLUSTER_ADMIN"
              paths={identifiedPathsV2}
            />
          ),
        },
        {
          path: 'detail',
          children: [
            { element: <Navigate to="/admin/donor/all" replace />, index: true },
            {
              path: ':id',
              element: (
                <DonorDetailPage
                  title="menu.donors.donor_overview"
                  activeRole="roles.CLUSTER_ADMIN"
                  paths={identifiedPathsV2}
                />
              ),
            },
          ],
        },
      ],
    },
    {
      path: 'tickets',
      children: [
        { element: <Navigate to="/admin/tickets/all" replace />, index: true },
        {
          path: 'all',
          element: (
            <AllTicketsPage
              title="menu.tickets.all_ticket"
              activeRole="roles.CLUSTER_ADMIN"
              paths={identifiedPathsV2}
            />
          ),
        },
        {
          path: 'detail',
          children: [
            { element: <Navigate to="/admin/tickets/all" replace />, index: true },
            {
              path: ':id',
              element: (
                <TicketDetailPage
                  title="menu.tickets.detail_ticket"
                  activeRole="roles.CLUSTER_ADMIN"
                  paths={identifiedPathsV2}
                />
              ),
            },
          ],
        },
        {
          path: 'add',
          element: (
            <AddNewTicketPage
              title="section.ticket.caption.add_new_ticket"
              activeRole="roles.CLUSTER_ADMIN"
              paths={identifiedPathsV2}
            />
          ),
        },
      ],
    },
    {
      path: 'settings',
      children: [
        { element: <Navigate to="/admin/settings/account-info" replace />, index: true },
        {
          path: 'account-info',
          element: (
            <SettingsAccountInfoPage
              title="menu.settings.account_info"
              activeRole="roles.CLUSTER_ADMIN"
              paths={identifiedPathsV2}
            />
          ),
        },
      ],
    },
    {
      path: 'maintenance-request',
      children: [
        { element: <Navigate to="/admin/maintenance-request/all" replace />, index: true },
        {
          path: 'all',
          element: (
            <MaintenanceRequestListPage
              title="section.maintenance_request.caption.all_request"
              activeRole="roles.CLUSTER_ADMIN"
              paths={identifiedPathsV2}
            />
          ),
        },
        {
          path: 'detail',
          children: [
            { element: <Navigate to="/admin/maintenance-request/all" replace />, index: true },
            {
              path: ':maintenance_request_id',
              element: (
                <DetailsMaintenanceRequestPage
                  title="section.maintenance_request.caption.detail_request"
                  activeRole="roles.CLUSTER_ADMIN"
                  paths={identifiedPathsV2}
                />
              ),
            },
          ],
        },
      ],
    },
    {
      path: 'volunteer',
      children: [
        { element: <Navigate to="/admin/volunteer/all" replace />, index: true },
        {
          path: 'all',
          element: (
            <AllVolunteerPage
              title="section.volunteers.caption.all_volunteer"
              activeRole="roles.CLUSTER_ADMIN"
              paths={identifiedPathsV2}
            />
          ),
        },
        {
          path: 'detail',
          children: [
            { element: <Navigate to="/admin/volunteer/all" replace />, index: true },
            {
              path: ':volunteer_request_id',
              children: [
                {
                  path: '',
                  element: (
                    <DetailVolunteerRequestPage
                      title="section.volunteers.caption.detail_volunteer"
                      activeRole="roles.CLUSTER_ADMIN"
                      paths={identifiedPathsV2}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          path: 'add-new-task',
          element: (
            <VolunteerAddNewTaskPage
              title="section.volunteers.button.add_new_task"
              activeRole="roles.CLUSTER_ADMIN"
              paths={identifiedPathsV2}
            />
          ),
        },
        {
          path: 'campaign-tasks',
          children: [
            { element: <Navigate to="/admin/volunteer/campaign-tasks/all" replace />, index: true },
            {
              path: 'all',
              element: (
                <VolunteerCampaignTaskListPage
                  title="section.volunteers.caption.campaign_task_for_volunteer"
                  activeRole="roles.CLUSTER_ADMIN"
                  paths={identifiedPathsV2}
                />
              ),
            },
            {
              path: 'detail',
              children: [
                {
                  element: <Navigate to="/admin/volunteer/campaign-tasks/all" />,
                  index: true,
                },
                {
                  path: ':campaign_task_id',
                  children: [
                    {
                      path: '',
                      element: (
                        <VolunteerCampaignTaskDetailPage
                          paths={identifiedPathsV2}
                          activeRole="roles.CLUSTER_ADMIN"
                          title="section.volunteers.caption.detail_campaign_task"
                        />
                      ),
                    },
                    {
                      path: 'edit',
                      element: (
                        <VolunteerCampaignTaskEditPage
                          paths={identifiedPathsV2}
                          activeRole="roles.CLUSTER_ADMIN"
                          title="section.volunteers.caption.edit_campaign_task"
                        />
                      ),
                    },
                    {
                      path: 'pending-volunteers',
                      element: (
                        <VolunteerApplyTaskListPage
                          paths={identifiedPathsV2}
                          type="pending"
                          activeRole="roles.CLUSTER_ADMIN"
                          title="section.admin.dashboard.label.total_pending_volunteers"
                        />
                      ),
                    },
                    {
                      path: 'assigned-volunteers',
                      element: (
                        <VolunteerApplyTaskListPage
                          type="approved"
                          paths={identifiedPathsV2}
                          activeRole="roles.CLUSTER_ADMIN"
                          title="section.admin.dashboard.label.total_assigned_volunteers"
                        />
                      ),
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
