import { io, Socket } from 'socket.io-client';
import { useMemo, useEffect, useReducer, useCallback } from 'react';

import { useAuthContext } from 'src/auth/hooks';

import { HOST_API } from 'src/config-global';
import { ActionMapType } from 'src/auth/types';
import {
  TSendMessage,
  TCreateTicket,
  TForwardedTicket,
  TNotificationEntity,
  TConversationEntity,
  TSocketMessageEntity,
} from 'src/socket-ticket/type';
import {
  ChatConversationEntity,
  ChatNotificationEntity,
  ChatSocketMessageEntity,
} from 'src/socket-chat/type';

import { SocketContext } from './socket-context';

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  CHAT_CONVERSATIONS = 'CHAT_CONVERSATIONS',
  TICKET_CONVERSATIONS = 'TICKET_CONVERSATIONS',
  CHAT_NOTIFICATIONS = 'CHAT_NOTIFICATIONS',
  TICKET_NOTIFICATIONS = 'TICKET_NOTIFICATIONS',
  // MESSAGES = 'MESSAGES',
}

type Payload = {
  [Types.INITIAL]: {
    loading: boolean;
    socket: Socket;
  };
  [Types.CHAT_NOTIFICATIONS]: {
    loading: boolean;
    chatNotifications: ChatNotificationEntity[];
  };
  [Types.TICKET_NOTIFICATIONS]: {
    loading: boolean;
    ticketNotifications: TNotificationEntity[];
  };
  [Types.CHAT_CONVERSATIONS]: {
    loading: boolean;
    chatConversations: ChatConversationEntity[];
  };
  [Types.TICKET_CONVERSATIONS]: {
    loading: boolean;
    ticketConversations: TConversationEntity[];
  };
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

type StateType = {
  loading: boolean;
  socket: Socket | null;
  ticketConversations: TConversationEntity[];
  ticketNotifications: TNotificationEntity[];
  chatConversations: ChatConversationEntity[];
  chatNotifications: ChatNotificationEntity[];
};

// type TSocketStatus = { type: string; message: string };

// ----------------------------------------------------------------------

const initialState: StateType = {
  socket: null,
  loading: true,
  ticketConversations: [],

  ticketNotifications: [],
  chatConversations: [],
  chatNotifications: [],
};

const reducer = (state: StateType, action: ActionsType) => {
  switch (action.type) {
    case Types.INITIAL:
      return {
        ...state,
        loading: action.payload.loading,
        socket: action.payload.socket,
      };
    case Types.CHAT_NOTIFICATIONS:
      return {
        ...state,
        loading: action.payload.loading,
        chatNotifications: action.payload.chatNotifications,
      };
    case Types.TICKET_NOTIFICATIONS:
      return {
        ...state,
        loading: action.payload.loading,
        ticketNotifications: action.payload.ticketNotifications,
      };
    case Types.CHAT_CONVERSATIONS:
      return {
        ...state,
        loading: action.payload.loading,
        chatConversations: action.payload.chatConversations,
      };
    case Types.TICKET_CONVERSATIONS:
      return {
        ...state,
        loading: action.payload.loading,
        ticketConversations: action.payload.ticketConversations,
      };
    default:
      return state;
  }
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

type Props = {
  children: React.ReactNode;
};

export function SocketProvider({ children }: Props) {
  const { user } = useAuthContext();
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);

      if (user && accessToken && HOST_API) {
        dispatch({
          type: Types.INITIAL,
          payload: {
            loading: false,
            socket: io(HOST_API, {
              withCredentials: true,
              extraHeaders: {
                Authorization: accessToken ?? '',
                'x-chosen-role': user.activeRole,
              },
            }),
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, [user]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const handleInitializeSocket = useCallback(() => initialize(), [initialize]);

  const handleDisconnectSocket = useCallback(() => {
    if (state.socket) {
      state.socket.disconnect();
    }
  }, [state]);

  const listenException = useCallback(async () => {
    if (state.socket && state.socket.connected) {
      state.socket.on('exception', ({ message }) => {
        console.error('exception', message.message);
      });
    }
  }, [state.socket]);

  const listenSuccess = useCallback(async () => {
    if (state.socket && state.socket.connected) {
      state.socket.on('success', (res) => {
        console.info('success', typeof res === 'object' ? res.message : res);
      });
    }
  }, [state.socket]);

  const listenForTicketEvents = useCallback(() => {
    if (state.socket && state.socket.connected) {
      state.socket.on('incoming_message', (res) => {
        const response: {
          incoming_message: TSocketMessageEntity;
          notification_sent: TNotificationEntity[];
        } = res;

        const existingConversationIndex = state.ticketConversations.findIndex(
          (conversation) => conversation.room_id === response.incoming_message.room_id
        );

        if (existingConversationIndex !== -1) {
          const updatedConversations = [...state.ticketConversations];

          const existingMessageIndex = updatedConversations[
            existingConversationIndex
          ].messages.findIndex((msg) => msg.message_id === response.incoming_message.message_id);

          if (existingMessageIndex === -1) {
            updatedConversations[existingConversationIndex].messages.push(
              response.incoming_message
            );
          }

          dispatch({
            type: Types.TICKET_CONVERSATIONS,
            payload: {
              loading: false,
              ticketConversations: updatedConversations,
            },
          });
        } else {
          const newConversation: TConversationEntity = {
            room_id: response.incoming_message.room_id,
            messages: [response.incoming_message],
          };

          dispatch({
            type: Types.TICKET_CONVERSATIONS,
            payload: {
              loading: false,
              ticketConversations: [...state.ticketConversations, newConversation],
            },
          });
        }
      });

      state.socket.on('new_notification', (res: TNotificationEntity) => {
        if (res) {
          dispatch({
            type: Types.TICKET_NOTIFICATIONS,
            payload: {
              loading: false,
              ticketNotifications: [res, ...state.ticketNotifications],
            },
          });
        }
      });
    }
  }, [state.socket, state.ticketConversations, state.ticketNotifications]);

  const listenForChatEvents = useCallback(() => {
    if (state.socket && state.socket.connected) {
      state.socket.on('incoming_chat_message', (res) => {
        const response: {
          incoming_message: ChatSocketMessageEntity;
          notification_sent: ChatNotificationEntity[];
        } = res.data;

        const existingConversationIndex = state.chatConversations.findIndex(
          (conversation) => conversation.room_id === response.incoming_message.room_id
        );

        if (existingConversationIndex !== -1) {
          const updatedConversations = [...state.chatConversations];

          const existingMessageIndex = updatedConversations[
            existingConversationIndex
          ].messages.findIndex((msg) => msg.message_id === response.incoming_message.message_id);

          if (existingMessageIndex === -1) {
            updatedConversations[existingConversationIndex].messages.push(
              response.incoming_message
            );
          }

          dispatch({
            type: Types.CHAT_CONVERSATIONS,
            payload: {
              loading: false,
              chatConversations: updatedConversations,
            },
          });
        } else {
          const newConversation: ChatConversationEntity = {
            room_id: response.incoming_message.room_id,
            messages: [response.incoming_message],
          };

          dispatch({
            type: Types.CHAT_CONVERSATIONS,
            payload: {
              loading: false,
              chatConversations: [...state.chatConversations, newConversation],
            },
          });
        }
      });

      state.socket.on('chat_new_notification', (res: ChatNotificationEntity) => {
        if (res) {
          dispatch({
            type: Types.CHAT_NOTIFICATIONS,
            payload: {
              loading: false,
              chatNotifications: [res, ...state.chatNotifications],
            },
          });
        }
      });

      state.socket.on('exception', ({ message }) => {
        console.error('exception', message.message);
      });
    }
  }, [state.socket, state.chatConversations, state.chatNotifications]);

  const handleAddTicket = useCallback(
    async (payload: TCreateTicket) => {
      if (state.socket && state.socket.connected) {
        state.socket.emit('create_ticket', payload);

        listenException();
        listenSuccess();
      }
    },
    [state.socket, listenException, listenSuccess]
  );

  const handleJoinTicketRoom = useCallback(
    async (room_id: string) => {
      if (state.socket && state.socket.connected) {
        state.socket.emit('join_ticket_room', {
          room_id,
        });

        listenException();
        listenSuccess();
      }
    },
    [state.socket, listenSuccess, listenException]
  );

  const handleSendMessage = useCallback(
    async (payload: TSendMessage) => {
      if (state.socket && state.socket.connected) {
        state.socket.emit('send_message', payload);
      }
    },
    [state]
  );

  const handleSendEvaluatorMessage = useCallback(
    async (payload: TSendMessage) => {
      if (state.socket && state.socket.connected) {
        state.socket.emit('send_chat_message', payload);
      }
    },
    [state]
  );

  const handleReadNotif = useCallback(
    (notif_id: string) => {
      const findNotif = state.ticketNotifications.find((v) => v.id === notif_id);

      if (findNotif) {
        const changeReadStatus = state.ticketNotifications.map((v) => ({
          ...v,
          read_status: v.id === notif_id ? true : v.read_status,
        }));

        dispatch({
          type: Types.TICKET_NOTIFICATIONS,
          payload: {
            loading: false,
            ticketNotifications: changeReadStatus,
          },
        });
      }
    },
    [state.ticketNotifications]
  );

  const handleMarkAllReadNotif = useCallback(() => {
    const readAll = state.ticketNotifications.map((v) => ({
      ...v,
      read_status: true,
    }));

    dispatch({
      type: Types.TICKET_NOTIFICATIONS,
      payload: {
        loading: false,
        ticketNotifications: readAll,
      },
    });
  }, [state]);

  const handleInitMessage = useCallback(
    (room_id: string, payload: TSocketMessageEntity[]) => {
      const existingConversationIndex = state.ticketConversations.findIndex(
        (conversation) => conversation.room_id === room_id
      );

      if (existingConversationIndex !== -1) {
        const updatedConversations = [...state.ticketConversations];

        updatedConversations[existingConversationIndex].messages = payload;

        dispatch({
          type: Types.TICKET_CONVERSATIONS,
          payload: {
            loading: false,
            ticketConversations: updatedConversations,
          },
        });
      } else {
        const newConversation: TConversationEntity = {
          room_id,
          messages: payload,
        };

        dispatch({
          type: Types.TICKET_CONVERSATIONS,
          payload: {
            loading: false,
            ticketConversations: [...state.ticketConversations, newConversation],
          },
        });
      }
    },
    [state.ticketConversations]
  );

  const handleInitEvaluatorMessages = useCallback(
    (room_id: string, payload: ChatSocketMessageEntity[]) => {
      const existingConversationIndex = state.chatConversations.findIndex(
        (conversation) => conversation.room_id === room_id
      );

      if (existingConversationIndex !== -1) {
        const updatedConversations = [...state.chatConversations];

        updatedConversations[existingConversationIndex].messages = payload;

        dispatch({
          type: Types.CHAT_CONVERSATIONS,
          payload: {
            loading: false,
            chatConversations: updatedConversations,
          },
        });
      } else {
        const newConversation: TConversationEntity = {
          room_id,
          messages: payload,
        };

        dispatch({
          type: Types.CHAT_CONVERSATIONS,
          payload: {
            loading: false,
            chatConversations: [...state.chatConversations, newConversation],
          },
        });
      }
    },
    [state.chatConversations]
  );

  const handleForwardedTicket = useCallback(
    (payload: TForwardedTicket) => {
      if (state.socket && state.socket.connected) {
        const emitPayload = {
          ticket_id: payload.ticket_id,
          target: payload.department === 'PROJECT' ? ['OPERATOR'] : [payload.target],
        };

        if (payload.status === 'FORWARDED') {
          state.socket.emit('forward_ticket', emitPayload);
        }

        if (payload.status === 'CLOSED') {
          state.socket.emit('close_ticket', emitPayload);
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [state.socket]
  );

  useEffect(() => {
    if (state.socket) {
      state.socket.connect();
    }
  }, [state.socket]);

  const memoizedValue = useMemo(
    () => ({
      socket: state.socket,
      loading: state.loading,
      ticketConversations: state.ticketConversations,
      ticketNotifications: state.ticketNotifications,
      chatConversations: state.chatConversations,
      chatNotifications: state.chatNotifications,
      handleInitializeSocket,
      handleDisconnectSocket,
      handleAddTicket,
      handleJoinTicketRoom,
      handleSendMessage,
      handleInitMessage,
      listenForTicketEvents,
      listenForChatEvents,
      handleSendEvaluatorMessage,
      handleInitEvaluatorMessages,
      handleReadNotif,
      handleMarkAllReadNotif,
      handleForwardedTicket,
    }),
    [
      state,
      handleInitializeSocket,
      handleDisconnectSocket,
      handleAddTicket,
      handleJoinTicketRoom,
      handleInitMessage,
      handleSendMessage,
      listenForTicketEvents,
      listenForChatEvents,
      handleSendEvaluatorMessage,
      handleInitEvaluatorMessages,
      handleReadNotif,
      handleMarkAllReadNotif,
      handleForwardedTicket,
    ]
  );

  return <SocketContext.Provider value={memoizedValue}>{children}</SocketContext.Provider>;
}
