export const ar = {
  dashboard: 'لوحة القيادة',
  statistics: 'إحصائيات',
  project: {
    title: 'المشاريع',
    all_project: 'كل المشروع',
    add_project: 'أضف المشروع',
    my_projects: 'مشاريعي',
    details: 'تفاصيل المشروع',
    edit: 'تحرير المشروع',
    edit_request: 'طلب تعديل المشروع',
    edit_project: 'تحرير المشروع',
    delete_project: 'حذف المشروع',
    project_requests: 'طلبات المشروع',
  },
  campaign: {
    title: 'الحملات',
    all_campaign: 'كل الحملة',
    add_campaign: 'أضف حملة',
    my_campaigns: 'حملاتي',
    details: 'تفاصيل الحملة',
    edit_campaign: 'تحرير الحملة',
    payment_history: 'سجل الدفع',
    task_volunteers: 'المهمة والمتطوعين',
    new_ticket: 'تذكرة جديدة',
    delete_campaign: 'حذف الحملة',
    edit_request: 'طلب تحرير الحملة',
    donor_list_campaign: 'قائمة المانحين للحملة',
    donor_list_project: 'قائمة المانحين للمشروع',
    vendor_list_campaign: 'قائمة الحملات',
    vendor_favorites_campaign: 'الحملات المفضلة',
    status: 'حالة الحملة',
    vendor_requests: 'طلبات البائع',
    detail_vendor_request: 'طلبات البائع التفصيلية',
    vendor_lists: 'قوائم البائعين',
    send_back_requests: 'إرسال طلبات مرة أخرى',
    send_it_back_to: 'أرسلها إلى',
  },
  marketplace: {
    title: 'المتجر',
    all_item: 'كل عنصر',
    add_item: 'اضافة عنصر',
    details: 'تفاصيل عنصر المشروع',
    edit_item: 'تعديل عنصر',
    edit_request_item: 'تحرير الطلب',
    donor_list_item: 'قائمة المانحين لعناصر المشروع',
  },
  donors: {
    title: 'الجهات المانحة',
    all_donor: 'جميع المانحين',
    donor_overview: 'نظرة عامة على المانحين',
  },
  vendors: {
    title: 'الباعة',
    all_vendor: 'كل البائع',
    vendor_requests: 'طلبات البائع',
    add_vendor: 'إضافة بائع جديد',
    detail_vendor: 'تفاصيل البائع',
    detail_requests_vendor: 'طلب بائع التفاصيل',
    milestone_progress: 'تقدم معلم',
    milestone_invoices: 'فواتير التقدم المعلم',
    payment_history_progress: 'تقدم سجل الدفع',
    edit: 'تحرير البائع',
  },
  operators: {
    title: 'العاملين',
    all_operator: 'كل العاملين',
    add_operator: 'إضافة عامل جديد',
    update_operator: 'تعديل عامل',
    detail_operator: 'تفاصيل العامل',
  },
  evaluators: {
    title: 'مقيم',
    maintenance_requests: `طلبات صيانة المقيمين`,
    evaluators_request: `طلب المقيم`,
    all_evaluator: 'كل المقيمين',
    add_evaluator: 'اضافة مقيم',
    update_evaluator: 'تعديل مقيم',
    details: `معلومات المقيم`,
    chat_with_super_admin: 'التحدث الى المسؤول',
    chat_with_evaluator: 'التحدث الى المقيِّم',
  },
  mosques: {
    add_mosque: 'اضافة مسجد جديد للصيانة',
  },
  tickets: {
    title: 'التذاكر',
    all_ticket: 'كل التذاكر',
    detail_ticket: 'تذكرة التفاصيل',
  },
  directories: 'الدلائل',
  maintenance_request: 'طلب صيانة',
  community: 'مجتمع',
  blogs: 'المدونات',
  items: 'أغراض',
  request: 'طلب',
  more_options: 'المزيد من الخيارات',
  select_your_options: 'حدد خياراتك',
  operator_page: 'صفحة المشغل',
  campaign_public_page: 'الصفحة العامة للحملة',
  parent_project_page: 'صفحة مشروع الوالدين',
  project_public_page: 'الصفحة العامة للمشروع',
  logout: 'تسجيل خروج',
  settings: {
    title: 'إعدادات',
    account_info: 'معلومات الحساب',
    security_settings: 'اعدادات الامان',
    my_cards: 'بطاقاتي',
    public_profile: 'إعدادات الملف الشخصي العام',
    profile_settings: 'إعدادات الملف الشخصي',
    delete_account: 'حذف الحساب',
  },
};
