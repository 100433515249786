export const ar = {
  dashboard: {
    label: {
      overalview: 'ملخص',
      total_projects: 'إجمالي المشاريع',
      total_campaigns: 'إجمالي الحملات',
      total_donors: 'إجمالي الجهات المانحة',
      total_vendors: 'إجمالي البائعين',
      total_operators: 'إجمالي المشغلين',
      total_volunteers: 'إجمالي المتطوعين',
      total_money_donated: 'إجمالي الأموال المتبرع بها',
      total_money_paid: 'إجمالي الأموال المدفوعة',
      total_donation_for_this_project: 'إجمالي التبرع لهذا المشروع',
      total_money_donated_this_project: 'إجمالي الأموال التي يتم التبرع بها لحملات المشروع',
      total_people_donated_this_project: 'إجمالي الأشخاص الذين تبرعوا لهذا المشروع',
      total_people_donated_this_project_campaigns: 'إجمالي الأشخاص الذين تبرعوا لحملات المشروع',
      total_campaign_only_money_paid: 'إجمالي حملة الأموال المدفوعة فقط',
      total_vendor_income_paid: 'إجمالي دخل البائعين المدفوع',
      total_donate_for_item: 'إجمالي الأموال التي يتم التبرع بها لهذا العنصر',
      total_needed_item: 'إجمالي البند المطلوب',
      total_provided_item: 'إجمالي العنصر المقدم',
      finished_milestones: 'المعالم النهائية',
      ongoing_milestones: 'المعالم المستمرة',
      total_milestones: 'مجموع المعالم',
      total_request_to_evaluate: 'طلب التقييم',
      mosque_evaluated: 'مسجد تقييم',
      archive_evaluated: 'تقييم الأرشيف',
      apply_evaluation: 'تطبيق التقييم',
      total_income: 'إجمالي الدخل',
      finished_campaigns: 'الحملات الانتهاء',
      ongoing_campaigns: 'الحملات المستمرة',
      total_amount_donation: 'إجمالي التبرعات',
      total_campaign: 'الحملات',
      total_amount_campaign: 'مقدار الحملات',
      total_project_item: 'عناصر المشروع',
      total_amount_project_item: 'كمية عناصر المشروع',
      total_zakat: 'زكاتس',
      total_amount_zakat: 'كمية الزكاة',
      charts: {
        donation: 'مخطط التبرع',
        total_paid_money: 'إجمالي الرسم البياني الأموال المدفوعة',
        vendor_and_only_money: 'البائع والرسم البياني المدفوع فقط',
        total_donation_project: 'إجمالي التبرعات لمخطط المشروع',
        total_donation_item_project: 'إجمالي التبرعات لهذا العنصر في هذا المشروع',
        DAILY: 'يوميًا',
        WEEKLY: 'أسبوعي',
        MONTHLY: 'شهريا',
        YEARLY: 'سنوي',
        CAMPAIGN: 'حملة',
        ITEM: 'عنصر المشروع',
        SILVER: 'زكاة الفضة',
        GOLD: 'الذهب الزكاة',
        MONEY: 'المال الزكاة',
        TRADE_GOODS: 'تجارة السلع زكاة',
        PROJECT: 'مشروع',
        MOSQUE: 'صيانة المسجد',
      },
      duration: 'مدة',
      number_of_milestones: 'عدد المعالم',
      type: 'يكتب',
      milestones: 'معالم',
      invoices: 'فواتير',
      total_income_until_today: 'إجمالي الدخل حتى اليوم',
      only_money: 'المال فقط',
      popover: {
        description:
          'الحملة فقط الأموال هي الأموال التي يدفعها المشغلون لك لشراء المواد والأشياء للحملة.لا يحتاج راتب البائع والبائع إلى تحميل أي فواتير يشترونها للحملة في صفحة الفواتير',
      },
      pay_to_vendor: 'دفع للبائع',
      total_assigned_volunteers: 'متطوعون مخصصون',
      total_pending_volunteers: 'متطوعون معلقون',
      parent_campaign_name: 'اسم حملة الوالدين',
      parent_project_name: 'اسم مشروع الوالدين',
    },
  },
};
